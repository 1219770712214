<template>
    <div>
                     
            <ol class="breadcrumb">
                <li class="breadcrumb-item"> Trang chủ </li>
                <li class="breadcrumb-item"><a href="">Thông tin chung </a>
                </li>
               
              
            </ol>
                     
            


            <form id="my_form" enctype="multipart/form-data" method="post" @submit="mySubmit"  v-if="is_loading">
                   <input type="hidden" :value="form.id" name="id" />
                        <div class="container">
                         
                          <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                              <a class="nav-link active" data-toggle="tab" href="#home">Tiếng Việt</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#menu1">English</a>
                            </li>
                           
                          </ul>

                      <!-- Tab panes -->
                          <div class="tab-content">
                            

                          <div id="home" class="container tab-pane active"><br>
                                <div class="">
                    
        
                   
            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header">
                                        <strong>Thông tin chung </strong>
                                        <small>Form</small>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">



                                    <div class="col-sm-10">

                                             <div class="form-group row">
                                                <div class='col-sm-3'>
                                                    <label for="name"> Chuyển đổi reponsive {{form.flagindex}}  </label>
                                                </div>
                                                <div class='col-sm-9'>
                                                    <div class="radio">
                                                            <label>
 <input
                         
                            name="flagindex"
                            type="radio"
                            value="1"
                            v-model="form.flagIndex"
                           
                          /> Có  
                                                            </label>
                                                     </div>
                                                    <div class="radio">
                                                            <label>
 <input
                            class=""
                            name="flagindex"
                            type="radio"
                            value="2"
                             v-model="form.flagIndex"
                            
                          /> Không  
                                                            </label>
                                                     </div>
                                                </div>
                                                   
                                                </div>
                                      </div>

                                

                                            
                                            <div class="col-sm-10">

                                                <div class="form-group" style="display: none;">
                                                    <label for="name">LOGO</label>
        <input type="file" name="logo"  accept="image/*"  class="form-control main_picture">
     
                                              
                                                  <div class="view_picture">
                <img :src="myurl+form.logo" />
                                                  </div>

                                                </div>

                                            </div>
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name">Tên SITE </label>
        <input type="text" name="sitename" v-model="form.sitename" class="form-control" required="required">
                                                  
                                                     
                                                   
                                                      <p class="text-danger">
                                                     
                                                      </p>
                                                    
                                                        <span  class="text-inline text-danger">*</span>
                                                    
                                                </div>

                                            </div>
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name">Tên  </label>
          <input type="text" name="name" v-model="form.name" class="form-control" required="required">
                                                </div>

                                            </div>
                                              <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Slogan  </label>
          <input type="text" name="slogan" v-model="form.slogan" class="form-control" >
                                                </div>

                                            </div>

                                           
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Mô tả </label>
         <textarea  name="description" v-model="form.description" class="form-control" ></textarea>
                                                  
                                                </div>

                                            </div>
                                            <div class="col-sm-10">

                                           <div class="form-group">
                                                    <label for="name"> Email  </label>
        <input type="text"  name="email" v-model="form.email" class="form-control" required="required">
                                                   
                                                </div>

                                            </div>
                                            <div class="col-sm-10">

                                                <div class="form-group">

                                                    <label for="name"> Số điện thoại </label>
         <input type="text" name="phone" v-model="form.phone" class="form-control">
                                                   
                                                </div>

                                            </div>
                                             <div class="col-sm-10">

                                                <div class="form-group">

                                                    <label for="name"> Hotline </label>
         <input type="text" name="hotline" v-model="form.hotline" class="form-control">
                                                
                                                 
                                                   
                                                </div>

                                            </div>
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Địa chỉ  </label>
        <input type="text" name="address" v-model="form.address" class="form-control">
                                                    
                                                </div>

                                            </div>
                                            

                                        </div>
                                       
                                    </div>
                                </div>

               
                            </div>

                             <div class="col-sm-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <strong>Social Network  </strong>
                                                <small>Form</small>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">

                                                    <div class="col-sm-10">

                                                       
                                                        <div class="form-group">
                                                            <label for="name"> Youtube  </label>
         <input type="text" name="social" v-model="form.social" class="form-control">
                                                          
                                                        </div>
                                                         
                                                     
                                                       

                                                    </div>
                                                    
                                                    

                                                </div>
                                               
                                            </div>
                                        </div>

               
                            </div>

                             <div class="col-sm-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <strong> SMTP </strong>
                                                <small>Form</small>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">

                                                    <div class="col-sm-10">

                                                       
                                                        <div class="form-group">
                                                            <label for="name"> Email   </label>
         <input type="text" name="flagcontent.email" v-model="flagcontent.email" class="form-control">
                                                          
                                                        </div>
                                                         
                                                     
                                                       

                                                    </div>


                                                     <div class="col-sm-10">

                                                       
                                                        <div class="form-group">
                                                            <label for="name"> Host   </label>
         <input type="text" name="flagcontent.host" v-model="flagcontent.host" class="form-control">
                                                          
                                                        </div>
                                                         
                                                     
                                                       

                                                    </div>

                                                    <div class="col-sm-10">

                                                       
                                                        <div class="form-group">
                                                            <label for="name"> Port   </label>
         <input type="text" name="flagcontent.port" v-model="flagcontent.port" class="form-control">
                                                          
                                                        </div>
                                                         
                                                     
                                                       

                                                    </div>


                                                    <div class="col-sm-10">

                                                       
                                                        <div class="form-group">
                                                            <label for="name"> Username   </label>
         <input type="text" name="flagcontent.username" v-model="flagcontent.username" class="form-control">
                                                          
                                                        </div>
                                                         
                                                     
                                                       

                                                    </div>


                                                    <div class="col-sm-10">

                                                       
                                                        <div class="form-group">
                                                            <label for="name"> Password   </label>
         <input type="text" name="flagcontent.password" v-model="flagcontent.password" class="form-control">
                                                          
                                                        </div>
                                                         
                                                     
                                                       

                                                    </div>

                                                       <div class="col-sm-10">

                                                       
                                                        <div class="form-group">
                                                            <label for="name"> TlS/SSL   </label>
         <input type="text" name="flagcontent.ssl" v-model="flagcontent.ssl" class="form-control">
                                                          
                                                        </div>
                                                         
                                                     
                                                       

                                                    </div>


                                                    
                                                    

                                                </div>
                                               
                                            </div>
                                        </div>

               
                            </div>







                            
                           
                  
             </div>

                          </div>
                          <div id="menu1" class="container tab-pane fade"><br>
          <div class="">
                    
           
                  
            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header">
                                        <strong>General </strong>
                                        <small>Form</small>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                           
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name">Name Site </label>
          <input type="text" name="sitename_en" v-model="form.sitename_EN" class="form-control" required="required">
                                                </div>

                                            </div>
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Name  </label>
         <input type="text" name="name_en" v-model="form.name_EN" class="form-control" required="required">
                                                </div>

                                            </div>


                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Slogan  </label>
         <input type="text" name="slogan_en" v-model="form.slogan_EN" class="form-control">
                                                  
                                                </div>

                                            </div>
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Description</label>
        <input type="text" name="description_en" v-model="form.description_EN" class="form-control">
                                                  
                                                </div>

                                            </div>
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name" required="required"> Address  </label>
        <input type="text" name="address_en" v-model="form.address_EN" class="form-control" >
                                                    
                                                </div>

                                            </div>
                                           

                                        </div>
                                       
                                    </div>
                                </div>

               
                            </div>

                            
                         

             </div>
                          </div>
                                          <div class="card-footer" style="width:90%;position: fixed;bottom: 0;">
                                                
                                                <button type="submit" class="btn btn-sm btn-primary">
                                                  <i class="fad fa-save"></i> Save </button>
                                                
                                            </div>
                          </div>

                              
                        </div>


                
                   

            
                      
                     
          </form>

          <div v-else > 
                <SkeletonTheme>
                        <Skeleton height="100px" v-for="k in 10" :key="k" ></Skeleton>
                </SkeletonTheme>
          </div>
                        
    </div>  
</template>

<script>

     import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
     import axios from "axios";
  export default {
    
    data(){
       return {
          form:{},
          form_en:{},
          error:[],
          is_loading:true,

          myurl:window.my_url,
          flagcontent:{
                email:"",
                host:"",
                port:"",
                username:"",
                password:"",
                ssl:""
          }
       }
    },
    created(){
                 axios.get(window.my_api+"api/general/get-general-by-id?generalid=7634739a-9df7-4e84-a52d-d5160e7b5f17",{
                    headers: window.my_header
                 })
                 .then(res=>{
                     
                    this.form=res.data.data;

                   this.flagcontent= JSON.parse( res.data.data.flagContent );
                 

                      
                 });

    },
    components:{
        Skeleton,
        SkeletonTheme
    },

     methods: {
        mySubmit: function (event) {
            event.preventDefault();
            this.is_loading=false;


            var formdata=new FormData(document.getElementById("my_form"));
            formdata.append("email_en",formdata.get('email'));
            formdata.append("flagcontent",JSON.stringify(this.flagcontent));
            formdata.append("flagcontent_en",JSON.stringify(this.flagcontent));

            axios.post(window.my_api+"api/general/create-or-update-general",formdata).then(()=>{
                 this.is_loading=true;
                     this.$swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Thêm thành công.",
                            showConfirmButton: false,
                            timer: 3500,
                          });
                   

                     //this.$router.go(0);

            });
            return true;
          
        }
      }
  }
</script>